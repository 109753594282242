const Skeleton = () => {
    return (
        <>
            <div>
                <div className="flex flex-col container mx-auto min-h-screen pb-8 bg-lynk-black max-w-xl relative antialiased animate-pulse">
                    <div className="px-4 py-10 pb-2">
                        <div class="h-7 rounded-full bg-neutral-600 w-52"></div>
                    </div>
                    <div className="flex flex-col min-w-full ">
                        <div class="flex items-center justify-center mt-8 mx-4 h-72 rounded-xl bg-neutral-600 animate-pulse"></div>
                        <div className="flex-none px-4">
                            <div className="flex flex-col">
                                <div className="py-8">
                                    <div class="h-5 mb-3 rounded-full bg-neutral-600 w-14"></div>
                                    <div class="h-5 mb-3 rounded-full bg-neutral-600 w-60"></div>
                                    <div class="h-2.5 mb-3 rounded-full bg-neutral-600"></div>
                                    <div class="h-2.5 mb-3 rounded-full bg-neutral-600"></div>
                                    <div class="h-2.5 mb-3 rounded-full bg-neutral-600"></div>
                                </div>
                                <div className="w-full border-t bg-lynk-neutral-400" />
                            </div>
                        </div>
                        <div class="flex items-center justify-center mt-8 mx-4 h-72 rounded-xl bg-neutral-600 animate-pulse"></div>
                        <div className="flex-none px-4">
                            <div className="flex flex-col">
                                <div className="py-8">
                                    <div class="h-5 mb-3 rounded-full bg-neutral-600 w-14"></div>
                                    <div class="h-5 mb-3 rounded-full bg-neutral-600 w-60"></div>
                                    <div class="h-2.5 mb-3 rounded-full bg-neutral-600"></div>
                                    <div class="h-2.5 mb-3 rounded-full bg-neutral-600"></div>
                                    <div class="h-2.5 mb-3 rounded-full bg-neutral-600"></div>
                                </div>
                                <div className="w-full border-t bg-lynk-neutral-400" />
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        </>
    )
  };

  export default Skeleton