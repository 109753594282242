import ListItem from "../list/list-item";
import { useEffect } from "react";
import { useState } from "react";
import { useCallback } from "react";

export default function List({content, contentIsLoadedNotification, itemId}) {

    // ----- DATA -----
    const [items, setItems] = useState([]);

    useEffect(() => {
        var listItems = content.items
        setItems(listItems);

        console.log("Scroll to item id:", itemId);
        scrollToElementById(itemId);
    });

    // ----- SKELETEON LOADING -----

    // Stores number of images in content array
    const [imageCount, setImageCount] = useState(0);

    // Stores number of images that has loaded
    const [imageLoadedCount, setImageLoadedCount] = useState(1000);

    // Simple implemenation. This will fire when a list item is renedered and tell core.js to init countdown to turn off skeleton loading. 
    const turnOffSkeleton = useCallback(() => {
        contentIsLoadedNotification();
        console.log("Turned off skeleton loading.")
    });

    const scrollToElementById = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'auto' });
        }
    };
    // UI

    if (items) {
        return (
            <>
            <div className="flex flex-col container mx-auto min-h-screen pb-8 bg-lynk-black max-w-xl relative antialiased">
                <div>
                    <h1 className="text-3xl text-lynk-heading-large leading-8 text-white px-4 py-10 pb-2">{content.title}</h1>
                </div>
                {items.map((item) => (
                    <ListItem key={item.id} item_id={item.id} visual_id={item.visual_id} image={item.image_path} title={item.title} body={item.body} turnOffSkeleton={turnOffSkeleton} />
                ))}
            </div>
            </>
        );
    } 
  }