import React from 'react';
import ReactDOM from 'react-dom/client';

// import {
//   createBrowserRouter,
//   RouterProvider,
//   HashRouter,
// } from "react-router-dom";

import {
  createHashRouter,
  RouterProvider,
} from "react-router-dom";


import "./index.css";
import App from './App';
import Root from './root';
import ErrorPage from "./error-page";
import reportWebVitals from './reportWebVitals';
import Core from './core/core';
// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//     <Router>
//       <App />
//     </Router>, document.getElementById('root')
// );


// const router = createBrowserRouter([
//   {
//     path: "/onboarding",
//     element: <App />,
//     errorElement: <ErrorPage />,
//   },
//   {
//     path: "/onboarding/dev",
//     element: <Carousel3 />,
//   },
//   {
//     path: "/onboarding/lender-welcome",
//     element: <LenderWelcome />,
//   },
//   {
//     path: "/onboarding/borrower-welcome",
//     element: <BorrowerWelcome />,
//   },
// ]);


const router = createHashRouter([
  {
    path: "/",
    element: <Root />,
    loader: Root,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "userinfo/:locale/:path/:type",
        element: <Core />,
      },
      {
        path: "userinfo/:locale/:path/:type/:item_id",
        element: <Core />,
      },
    ],
  },
]);


ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

reportWebVitals();
